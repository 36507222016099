

















































































import { Component, Prop, Vue } from 'vue-property-decorator'

import OrderDiscount from '@/components/forms/order/OrderDiscount.vue'
import PaymentInvoice from '@/components/_uikit/PaymentInvoice.vue'
import Tag from '@/components/_uikit/Tag.vue'
import { CourseType, MasterOrderLargeResource, OrderStatus, PromotionDiscountType } from '@/store/types'
import MasterOrdersModule from '@/store/modules/master/orders'

@Component({
  components: {
    OrderDiscount,
    PaymentInvoice,
    Tag,
  },
})
export default class OrderProductsForm extends Vue {
  @Prop({ required: true })
  private hrefOffer!: string

  @Prop({ default: null })
  private orderMore!: MasterOrderLargeResource

  @Prop({ default: false })
  private readonly!: boolean

  private get order () {
    return this.orderMore || MasterOrdersModule.order as MasterOrderLargeResource
  }

  private get isAppliedDiscount() {
    return this.order.sales.find(sale => sale.isApplied)
  }

  private get requiredWhenBuyDiscounts() {
    return this.order.sales.filter(sale => sale.isRequiredWhenBuy)
  }

  private get isPaid () {
    return this.order.status.value === OrderStatus.PAID
  }

  private get isWait() {
    return this.order.status.value === OrderStatus.WAIT
  }

  private get isCanceled() {
    return this.order.status.value === OrderStatus.CANCELED
  }

  private get orderOriginalPriceSum() {
    let sum = 0
    this.order.groups.map(group => group.packages.forEach(pack => sum += pack.package ? pack.package.originalPrice : pack.originalPrice))
    return sum
  }

  private get isPercent(): boolean {
    return Boolean(this.order?.promoCode?.promotion.discountType.value === PromotionDiscountType.PERCENT)
  }

  private get isSpecial() {
    return this.order.groups[0].course.type === this.special
  }

  private isRecurrentActivated = false

  private updateOrder(order: MasterOrderLargeResource) {
    this.$emit('changeOrder', order)
  }

  private recurrentToggle(value: boolean) {
    this.$emit('recurrentToggle', {
      ...this.orderMore,
      isInitRecurringPayment: value,
    })
  }

  private special = CourseType.SPECIAL
}
