




















































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterOrdersModule from '@/store/modules/master/orders'
import {
  MasterOrderLargeResource,
  MasterOrdersOrderIdCodePostParams, OrderSaleLargeResource,
} from '@/store/types'

@Component({
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class OrderDiscount extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private orderMore!: MasterOrderLargeResource

  @Prop({ default: false })
  private readonly!: boolean

  private showPromocodeForm = false
  private form: MasterOrdersOrderIdCodePostParams = {
    code: '',
  }

  private get order () {
    return this.orderMore || MasterOrdersModule.order as MasterOrderLargeResource
  }

  // Примененные скидки
  private get applyDiscounts() {
    return this.order.sales.filter(sale => sale.isApplied && !sale.isRequiredWhenBuy)
  }

  private get discountValue () {
    return this.order.sales
      .filter(item => !item.isRequiredWhenBuy)
      .reduce((acc: number, cur: OrderSaleLargeResource) => {
        return acc + cur.salePrice
      }, 0)
  }

  private get discountValueApplied () {
    return this.order.sales
      .filter(item => !item.isRequiredWhenBuy && item.isApplied)
      .reduce((acc: number, cur: OrderSaleLargeResource) => {
        return acc + cur.salePrice
      }, 0)
  }

  @Debounce(500)
  @Bind
  private handleAddPromocode () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          MasterOrdersModule.addPromocodeToOrder({
            orderID: this.order.id,
            params: this.form,
          })
            .then((response: MasterOrderLargeResource) => {
              this.$emit('updateOrder', response)
              this.showPromocodeForm = false
              this.notifySuccess('Промокод применен')
            })
            .catch(this.notifyError)
        }
      })
  }

  @Debounce(500)
  @Bind
  private handleDeletePromocode () {
    MasterOrdersModule.deletePromocodeFromOrder(this.order.id)
      .then((response: MasterOrderLargeResource) => {
        this.$emit('updateOrder', response)
        this.notifySuccess('Промокод удален')
      })
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(500)
  private handleApplyDiscount(id: number) {
    MasterOrdersModule.applyOneDiscount({ orderId: this.order.id, saleId: id })
      .then(response => {
        this.notifySuccess('Скидка на заказ применена')
        this.$emit('updateOrder', response)
      })
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(500)
  private handleRemoveDiscount(id: number) {
    MasterOrdersModule.removeOneDiscount({ orderId: this.order.id, saleId: id })
      .then(response => {
        this.notifySuccess('Скидка на заказ отменена')
        this.$emit('updateOrder', response)
      })
      .catch(this.notifyError)
  }
}
